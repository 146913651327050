import React from "react";
import {Link} from "react-router-dom";
import hoc from "./hoc";

const menu = [
    { name: "Dashboard", link: "/" },
    { name: "Music", link: "/music" },
    { name: "Musical categories", link: "/musical-categories" },
    { name: "Musical instruments", link: "/musical-instruments" },
    { name: "Breath", link: "/breath" }
];


const Header = ({logout, changeLocation, active}) => (
    <header className={"header"}>
        <div className={"header__container"}>
            <div className={"menu"}>
                {menu.map((item, index) => (
                    <Link
                        onClick={() => changeLocation(item.link)}
                        className={active === item.link ? "menu__item active" : "menu__item"}
                        to={item.link}
                        key={index}>
                        {item.name}
                    </Link>
                ))}
            </div>
            <div onClick={logout} className={"logout button"}>Log out</div>
        </div>
    </header>
)


export default hoc(Header)