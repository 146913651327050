import React from "react";
import { API_PREFIX } from "../../etc/config"

const withAuth = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                password: "",
                login: "",
                errors: []
            };
            this.handlerChange = this.handlerChange.bind(this);
            this.submit = this.submit.bind(this)
        }

        componentDidMount() {

        }

        handlerChange(name, value) {
            this.setState({[name]: value})
        }



        submit(e) {
            e.preventDefault();
            const { password, login } = this.state;
            const errors = [];
            if (!password) errors.push("password");
            if (!login) errors.push("login");
            this.setState({ errors });
            if (errors.length > 0) {
                return
            }
            fetch(`${API_PREFIX}users/auth`, {
                method: "post",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login, password })
            })
                .then(res => res.json())
                .then(res => {
                    if (!res.ok) {
                        alert("Incorrect login and/or password")
                    } else {
                        this.props.checkAuth();
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { login, password, errors } = this.state
            return <Component
                handlerChange={this.handlerChange}
                submit={this.submit}
                login={login}
                password={password}
                errors={errors}
                {...this.props} />
        }
    }
    HOC.displayName = `withAuth(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withAuth