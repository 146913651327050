import React from "react";
import { API_PREFIX } from "../../etc/config"

const withMusics = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                contextMenuPosition: null,
                contextMenuProperties: null,
                search: "",
                category: { name: "All categories", _id: "" },
                instrument: { name: "All instruments", _id: "" },
                page: 1,
                count: 0,
                categories: [],
                instruments: []
            };
            this.callContextMenu = this.callContextMenu.bind(this);
            this.removeContext = this.removeContext.bind(this);
            this.get = this.get.bind(this);
            this.removeItem = this.removeItem.bind(this);
            this.more = this.more.bind(this);
            this.filterChange = this.filterChange.bind(this);
        }

        componentDidMount() {
            this.get();
            window.addEventListener("click", this.removeContext)
        }

        componentWillUnmount() {
            window.removeEventListener("click", this.removeContext)
        }

        removeContext() {
            this.setState({contextMenuProperties: null, contextMenuPosition: null})
        }

        removeItem(item) {
            fetch(`${API_PREFIX}admin/musics/delete/${item._id}`, {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                      const { data } = this.state;
                      const idx = data.findIndex(x => x._id === item._id);
                      if (idx > -1) {
                          data.splice(idx, 1);
                          this.setState({data})
                      }
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        callContextMenu(event, id) {
            event.preventDefault()
            this.setState({contextMenuProperties: {id}, contextMenuPosition: {x: event.clientX, y: event.clientY}})
        }

        more() {
            this.setState({page: this.state.page + 1}, () => {
                this.get();
            })
        }

        filterChange(name, value) {
            this.setState({[name]: value, page: 1, data: []}, () => {
                this.get();
            })
        }

        get() {
            const {search, instrument, category, page} = this.state;
            fetch(`${API_PREFIX}admin/musics/get?page=${page}&search=${search}&instrument=${instrument._id}&category=${category._id}`, {
                method: "get",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    if (res.ok) {
                        this.setState({
                            data: [...this.state.data, ...res.data],
                            count: res.count,
                            categories: [{ name: "All categories", _id: "" }, ...res.categories],
                            instruments: [{ name: "All instruments", _id: "" }, ...res.instruments]
                        });
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { data,
                contextMenuPosition,
                contextMenuProperties,
                count,
                search,
                categories,
                category,
                instruments,
                instrument} = this.state
            return (
                <Component
                    removeItem={this.removeItem}
                    callContextMenu={this.callContextMenu}
                    more={this.more}
                    filterChange={this.filterChange}
                    search={search}
                    data={data}
                    count={count}
                    contextMenuProperties={contextMenuProperties}
                    contextMenuPosition={contextMenuPosition}
                    instruments={instruments}
                    categories={categories}
                    category={category}
                    instrument={instrument}
                    {...this.props} />

                )
        }
    }
    HOC.displayName = `withMusics(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withMusics