import React from "react";


const ContextMenu = ({menu, position}) => (
    <div style={{left: `${position.x}px`, top: `${position.y}px`}} className={"context-menu"}>
        {menu.map((item, index) => (
            <div className={"context-menu__item"} key={index} onClick={() => item.handler(item.properties)}>
                {item.name}
            </div>
        ))}
    </div>
)

export default ContextMenu