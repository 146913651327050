import React from "react";


const withTextField = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isActive: false
            };
            this.focus = this.focus.bind(this)
            this.blur = this.blur.bind(this)
        }

        componentDidMount() {
            this.setState({isActive: (String(this.props.value).length > 0)})
        }

        UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
            if (this.props.value.length === 0 && String(nextProps.value).length > 0) {
                this.setState({isActive: true})
            }
        }

        focus() {
            this.setState({isActive: true})
        }

        blur(e) {
            if (e.target.value.length === 0) {
                this.setState({isActive: false})
            }
        }

        render() {
            return <Component
                focus={this.focus}
                blur={this.blur}
                isActive={this.state.isActive}
                {...this.props} />
        }
    }
    HOC.displayName = `withTextField(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withTextField