import React from "react";
import { API_PREFIX } from "../../etc/config"

const withInstruments = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                contextMenuPosition: null,
                contextMenuProperties: null
            };
            this.callContextMenu = this.callContextMenu.bind(this);
            this.removeContext = this.removeContext.bind(this);
            this.get = this.get.bind(this);
            this.removeItem = this.removeItem.bind(this)
        }

        componentDidMount() {
            this.get()
            window.addEventListener("click", this.removeContext)
        }

        componentWillUnmount() {
            window.removeEventListener("click", this.removeContext)
        }

        removeContext() {
            this.setState({contextMenuProperties: null, contextMenuPosition: null})
        }

        removeItem(item) {
            fetch(`${API_PREFIX}admin/instruments/delete/${item.id}`, {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                      const { data } = this.state;
                      const idx = data.findIndex(x => x._id === item.id);
                      if (idx > -1) {
                          data.splice(idx, 1);
                          this.setState({data})
                      }
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        callContextMenu(event, id) {
            event.preventDefault()
            this.setState({contextMenuProperties: {id}, contextMenuPosition: {x: event.clientX, y: event.clientY}})
        }

        get() {
            fetch(`${API_PREFIX}admin/instruments/get`, {
                method: "get",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({data: res.data});
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { data, contextMenuPosition, contextMenuProperties } = this.state
            return (
                <Component
                    removeItem={this.removeItem}
                    callContextMenu={this.callContextMenu}
                    data={data}
                    contextMenuProperties={contextMenuProperties}
                    contextMenuPosition={contextMenuPosition}
                    {...this.props} />

                )
        }
    }
    HOC.displayName = `withInstruments(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withInstruments