import React, {Fragment} from "react";
import { API_PREFIX } from "../../etc/config"
import { Redirect } from 'react-router'

const withBreathItem = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                name: "",
                inhaleDescription: "",
                inhale: 4000,
                exhaleDescription: "",
                exhale: 4000,
                preExhale: 500,
                postExhale: 500,
                duration: 1,
                errors: [],
                redirect: false,
                isNew: true,
                time: 0,
                action: "postExhale",
                scale: 0
            };


            this.handlerChange = this.handlerChange.bind(this);
            this.submit = this.submit.bind(this);
            this.get = this.get.bind(this);
            this.animate = this.animate.bind(this);
            this.stopAnimate = this.stopAnimate.bind(this);
            this.reloadAnimate = this.reloadAnimate.bind(this);

            this.breathCircle = React.createRef();
            this.timer = null
        }

        componentDidMount() {
            if (this.props.match.params.id !== "new") {
                this.setState({isNew: false});
                this.get();
            }
            this.breathCircle.current.style.transform = "scale(0)";
            this.animate()
        }

        componentWillUnmount() {
            this.stopAnimate();
        }

        animate() {
            this.timer = setInterval(() => {
                if (this.state.action === "postExhale") {
                    if (this.state.time >= this.state.postExhale) {
                        this.setState({time: 0, action: "inhale", scale: 0});
                    } else {
                        this.setState({time: this.state.time + 1000/30});
                    }
                } else if (this.state.action === "inhale") {
                    if (this.state.time >= this.state.inhale) {
                        this.setState({time: 0, action: "preExhale"});
                    } else {
                        this.breathCircle.current.style.transform = `scale(${this.state.scale})`;
                        const speed = 1000 / this.state.inhale / 30;
                        this.setState({time: this.state.time + 1000/30, scale: this.state.scale + speed});
                    }

                } else if (this.state.action === "preExhale") {
                    if (this.state.time >= this.state.preExhale) {
                        this.setState({time: 0, action: "exhale", scale: 1});
                    } else {
                        this.setState({time: this.state.time + 1000/30});
                    }
                } else if (this.state.action === "exhale") {
                    if (this.state.time >= this.state.exhale) {
                        this.setState({time: 0, action: "postExhale"});
                    } else {
                        this.breathCircle.current.style.transform = `scale(${this.state.scale})`;
                        const speed = - 1000 / this.state.exhale / 30;
                        this.setState({time: this.state.time + 1000/30, scale: this.state.scale + speed});
                    }

                }


            }, 1000/30)
        }

        stopAnimate() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
                if (this.breathCircle && this.breathCircle.current) {
                    this.breathCircle.current.style.transform = "scale(0)";
                }

            }
        }

        reloadAnimate() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
                this.breathCircle.current.style.transform = "scale(0)";
            }
            this.setState({time: 0, action: "postExhale", scale: 0}, this.animate);
        }

        handlerChange(name, value) {
            if ((name === "inhale" || name === "exhale" || name === "preExhale" || name === "postExhale" || name === "duration")
            && isNaN(Number(value))) {
                return
            }
            this.setState({[name]: value}, () => {
                if (name === "inhale" || name === "exhale" || name === "preExhale" || name === "postExhale") {
                    this.reloadAnimate();
                }
            })
        }

        get() {
            fetch(`${API_PREFIX}admin/breath/get/${this.props.match.params.id}`, {
                method: "get",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({
                            name: res.data.name,
                            inhaleDescription: res.data.inhaleDescription,
                            inhale: res.data.inhale,
                            exhaleDescription: res.data.exhaleDescription,
                            exhale: res.data.exhale,
                            preExhale: res.data.preExhale,
                            postExhale: res.data.postExhale,
                            duration: res.data.duration})
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        submit(e) {
            e.preventDefault();
            const { name, inhaleDescription, inhale, exhaleDescription,
                exhale, preExhale, postExhale, duration } = this.state;
            const errors = [];
            if (!name) errors.push("name");
            if (!inhaleDescription) errors.push("inhaleDescription");
            if (!inhale) errors.push("inhale");
            if (!exhaleDescription) errors.push("exhaleDescription");
            if (!exhale) errors.push("exhale");
            if (!duration) errors.push("duration");

            this.setState({ errors });

            if (errors.length > 0) {
                return
            }
            const {isNew} = this.state;
            const body =    `name=${name}
                            &inhaleDescription=${inhaleDescription}
                            &inhale=${inhale}
                            &exhaleDescription=${exhaleDescription}
                            &exhale=${exhale}
                            &preExhale=${preExhale}
                            &postExhale=${postExhale}
                            &duration=${duration}
                            ${!isNew ? `&id=${this.props.match.params.id}` : ""}`
            fetch(`${API_PREFIX}admin/breath/${isNew ? "add" : "edit"}`, {
                method: "post",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({redirect: true})
                    } else {
                        alert("Error, try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { name, errors, redirect, inhaleDescription, inhale, exhaleDescription,
                exhale, preExhale, postExhale, duration, action } = this.state;
            return (
                <Fragment>
                    {redirect ? (
                        <Redirect to={"/breath"} />
                    ) : (
                        <Component
                            handlerChange={this.handlerChange}
                            submit={this.submit}
                            name={name}
                            inhaleDescription={inhaleDescription}
                            inhale={inhale}
                            exhaleDescription={exhaleDescription}
                            exhale={exhale}
                            preExhale={preExhale}
                            postExhale={postExhale}
                            duration={duration}
                            action={action}
                            errors={errors}
                            breathCircle={this.breathCircle}
                            {...this.props} />
                    )}
                </Fragment>

                )
        }
    }
    HOC.displayName = `withBreathItem(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withBreathItem