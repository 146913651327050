import React from "react";

import withMusicItem from "./hoc";

import TextField from "../../Components/TextField";
import FileLoader from "../../Components/FileLoader";
import Checkbox from "../../Components/Checkbox";

const MusicItem = ({categories,
                       instruments,
                       chooseCategories,
                       chooseInstruments,
                       chooseItems,
                       filename,
                       name,
                       handlerChange,
                       artist, submit, errors}) => (
    <div className={"page music-item-page"}>
        <form onSubmit={submit}>
            <TextField
                isError={errors.includes("name")}
                handlerChange={handlerChange}
                value={name}
                name={"name"}
                className={"input"}
                type={"text"}
                label={"Name"}/>
            <TextField
                isError={errors.includes("artist")}
                handlerChange={handlerChange}
                value={artist}
                name={"artist"}
                className={"input"}
                type={"text"}
                label={"Artist"}/>
                <div className={"checkers"}>
                    <h4>Choose one or some categories:</h4>
                    {categories.map(item => (
                        <Checkbox key={item._id}
                                  name={"categories"}
                                  label={item.name}
                                  isActive={chooseCategories.findIndex(x => x === item._id) > -1}
                                  handler={chooseItems}
                                  value={item._id} />
                    ))}
                </div>
                <div className={"checkers"}>
                    <h4>Choose one or some instruments:</h4>
                    {instruments.map(item => (
                        <Checkbox key={item._id}
                                  name={"instruments"}
                                  label={item.name}
                                  isActive={chooseInstruments.findIndex(x => x === item._id) > -1}
                                  handler={chooseItems}
                                  value={item._id} />
                    ))}
                </div>

                <FileLoader handlerChange={handlerChange} value={filename} name={"filename"} title={"Load mp3 file"}/>
            <button className={"button"} type={"submit"}>Save</button>
        </form>
    </div>
);

export default withMusicItem(MusicItem)