import React from "react";

import TextField from "../../Components/TextField";

import withBreathItem from "./hoc";


const BreathItem =
    ({handlerChange, submit, errors, name, inhaleDescription, inhale, exhaleDescription,
         exhale, preExhale, postExhale, breathCircle, duration, action}) => (
    <div className={"page category-item"}>
        <form  onSubmit={submit}>
            <TextField
                isError={errors.includes("name")}
                handlerChange={handlerChange}
                value={name}
                name={"name"}
                className={"input"}
                type={"text"}
                label={"Breath name"}/>
            <TextField
                isError={errors.includes("inhale")}
                handlerChange={handlerChange}
                value={inhale}
                name={"inhale"}
                className={"input"}
                type={"text"}
                label={"Inhale time (milliseconds)"}/>
            <TextField
                isError={errors.includes("inhaleDescription")}
                handlerChange={handlerChange}
                value={inhaleDescription}
                name={"inhaleDescription"}
                className={"input"}
                type={"text"}
                label={"Inhale comment"}/>
            <TextField
                isError={errors.includes("preExhale")}
                handlerChange={handlerChange}
                value={preExhale}
                name={"preExhale"}
                className={"input"}
                type={"text"}
                label={"Hold the breath (milliseconds)"}/>
            <TextField
                isError={errors.includes("exhale")}
                handlerChange={handlerChange}
                value={exhale}
                name={"exhale"}
                className={"input"}
                type={"text"}
                label={"Exhale time (milliseconds)"}/>
            <TextField
                isError={errors.includes("exhexhaleDescriptionale")}
                handlerChange={handlerChange}
                value={exhaleDescription}
                name={"exhaleDescription"}
                className={"input"}
                type={"text"}
                label={"Exhale comment"}/>
            <TextField
                isError={errors.includes("postExhale")}
                handlerChange={handlerChange}
                value={postExhale}
                name={"postExhale"}
                className={"input"}
                type={"text"}
                label={"Hold the breath (milliseconds)"}/>
            <TextField
                isError={errors.includes("duration")}
                handlerChange={handlerChange}
                value={duration}
                name={"duration"}
                className={"input"}
                type={"text"}
                label={"Duration (seconds)"}/>
            <button className={"button"} type={"submit"}>Save</button>
        </form>
        <div className={"breath"}>
            <div className={"breath-circle"}>
                <div ref={breathCircle} className={"breath-circle__animate"}/>
            </div>
            <div className={"comment"}>{action === "inhale" ? inhaleDescription : action === "exhale" ? exhaleDescription : ""}</div>
        </div>
    </div>
);

export default withBreathItem(BreathItem)