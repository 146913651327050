import React from "react";
import openSocket from "socket.io-client";

import ss from "socket.io-stream";

import { SOCKET_SERVER } from "../../etc/config"

const withFileLoader = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                load: false,
                percent: 0,
                filename: this.props.value,
                fieldName: ""
            };
            this.socket = openSocket(SOCKET_SERVER);

            this.socketConnect = this.socketConnect.bind(this);
            this.uploadFile = this.uploadFile.bind(this);
            this.loadSuccessful = this.loadSuccessful.bind(this);
            this.deleteFile = this.deleteFile.bind(this);
            this._isMounted = false;
        }

        componentDidMount() {
            if (this.props.value) {
                this.setState({ filename: this.props.value });
            }
            this.socket.open();
            this._isMounted = true;
            this.socketConnect();
            this.loadSuccessful();
        }
        UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.value) {
                this.setState({ filename: nextProps.value });
            }
        }
        componentWillUnmount() {
            this._isMounted = false;
            this.setState({ load: false, percent: 0, filename: "", fieldName: "" });
            this.socket.close();
            this.socket.on("disconnect", () => {
                console.log("disconnect");
            });
        }
        socketConnect() {
            this.socket.on("connect", () => {
                console.log("client");
            });
        }
        loadSuccessful() {
            this.socket.on("load_successful", response => {
                if (response) {
                    this.setState({ load: false, percent: 0, filename: response }, () => {
                        const { fieldName, filename } = this.state;
                        this.props.handlerChange(fieldName, filename);
                    });
                }
            });
        }
        deleteFile(e) {
            if (this._isMounted) {
                const { filename } = this.state;
                this.setState({ filename: "" }, () => {
                    this.socket.emit("delete_music", filename);
                    this.props.handlerChange(this.state.fieldName, this.state.filename);
                });
            }
        }
        uploadFile(e) {
            if (this._isMounted) {
                const { name } = e.target;
                this.setState({ fieldName: name });
                const file = e.target.files[0];
                const filename = file.name;
                const filesize = file.size;
                const enc = e.target.encoding;
                const stream = ss.createStream();

                ss(this.socket).emit("upload_music", stream, {
                    data: file,
                    size: filesize,
                    name: filename,
                    enc: enc
                });
                const blobStream = ss.createBlobReadStream(file);
                let size = 0;
                blobStream.on("data", chunk => {
                    size += chunk.length;
                    let percent = Math.floor((size / file.size) * 100);
                    this.setState({ load: true, percent });
                });
                blobStream.pipe(stream);
                blobStream.on("end", () => {
                    blobStream.off("data", chunk => {
                        size += chunk.length;
                        let percent = Math.floor((size / file.size) * 100);
                        this.setState({ load: true, percent });
                    });
                });
            }
        }

        render() {
            const { load, percent, filename } = this.state;
            return (
                <Component
                    uploadFile={this.uploadFile}
                    {...this.props}
                    load={load}
                    percent={percent}
                    filename={filename}
                    deleteFile={this.deleteFile}
                />
            );
        }
    }
    HOC.displayName = `withFileLoader(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withFileLoader