import React from "react";
import {Link} from "react-router-dom";

import withMusics from "./hoc";
import {MEDIA_PREFIX} from "../../etc/config"

import Filter from "./Components/Filter";

const Music = ({ data, count, more, search, filterChange, categories, category, instruments, instrument, removeItem }) => (
    <div className={"page music-page"}>
        <Filter categories={categories} category={category} instruments={instruments} instrument={instrument} search={search} filterChange={filterChange} />
        <div className={"music-page__list"}>
            {data.map(item => (
                <div key={item._id} className={"item"}>
                    <div className={"info"}>
                        <p className={"item__artist"}>{item.artist}</p>
                        <p className={"item__name"}>{item.name}</p>
                    </div>
                    <div className={"buttons"}>
                        <a className={"item__filename"} href={`${MEDIA_PREFIX}/Music/${item.filename}`} target="_blank" rel="noreferrer noopener" />
                        <Link to={`/music/${item._id}`} className={"button edit"}>Edit</Link>
                        <div onClick={() => removeItem(item)} className={"button delete"}>Delete</div>
                    </div>
                </div>
            ))}
        </div>
        {count > data.length ? (
            <div onClick={more} className={"button more"}>Show more</div>
        ) : ""}
        <Link to={"/music/new"} className={"button add-button"}>+</Link>
    </div>
);

export default withMusics(Music)