import React, {Fragment} from "react";
import { API_PREFIX } from "../../etc/config"
import { Redirect } from 'react-router'

const withMusicItem = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                name: "",
                artist: "",
                filename: "",
                errors: [],
                redirect: false,
                isNew: true,
                categories: [],
                chooseCategories: [],
                instruments: [],
                chooseInstruments: []
            };
            this.handlerChange = this.handlerChange.bind(this);
            this.submit = this.submit.bind(this);
            this.init = this.init.bind(this);
            this.chooseItems = this.chooseItems.bind(this);
        }

        componentDidMount() {
            if (this.props.match.params.id !== "new") {
                this.setState({isNew: false});
            }
            this.init()
        }

        handlerChange(name, value) {
            this.setState({[name]: value})
        }


        chooseItems(name, value, isActive) {
            const {chooseCategories, chooseInstruments} = this.state;
            if (name === "categories") {
                if (isActive) {
                    chooseCategories.push(value);
                } else {
                    const idx = chooseCategories.findIndex(x => x === value);
                    if (idx > -1) {
                        chooseCategories.splice(idx, 1);
                    }
                }
            } else {
                if (isActive) {
                    chooseInstruments.push(value);
                } else {
                    const idx = chooseInstruments.findIndex(x => x === value);
                    if (idx > -1) {
                        chooseInstruments.splice(idx, 1);
                    }
                }
            }
            this.setState({chooseCategories, chooseInstruments});
        }

        init() {
            const {id} = this.props.match.params;
            fetch(`${API_PREFIX}admin/musics/get-one/${id !== "new" ? id : ""}`, {
                method: "get",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        if (this.state.isNew) {
                            this.setState({
                                categories: res.categories,
                                instruments: res.instruments
                            })
                        } else {
                            this.setState({
                                categories: res.categories,
                                instruments: res.instruments,
                                chooseCategories: res.data.categories,
                                chooseInstruments: res.data.instruments,
                                filename: res.data.filename,
                                name: res.data.name,
                                artist: res.data.artist
                            })
                        }

                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        submit(e) {
            e.preventDefault();
            const { name, artist, filename, chooseCategories, chooseInstruments } = this.state;
            const errors = [];
            if (!name) errors.push("name");
            if (!artist) errors.push("artist");
            if (!filename) {
                errors.push("filename");
                alert("Load a file!")
            }

            this.setState({ errors });

            if (errors.length > 0) {
                return
            }
            const {isNew} = this.state;
            fetch(`${API_PREFIX}admin/musics/${isNew ? "add" : "edit"}`, {
                method: "post",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `name=${name}&filename=${filename}&artist=${artist}&categories=${chooseCategories}&instruments=${chooseInstruments}${!isNew ? `&id=${this.props.match.params.id}` : ""}`
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({redirect: true})
                    } else {
                        alert("Error, try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { name,
                errors,
                redirect,
                instruments,
                categories,
                chooseInstruments,
                chooseCategories,
                filename,
                artist} = this.state
            return (
                <Fragment>
                    {redirect ? (
                        <Redirect to={"/music"} />
                    ) : (
                        <Component
                            handlerChange={this.handlerChange}
                            submit={this.submit}
                            chooseItems={this.chooseItems}
                            name={name}
                            artist={artist}
                            filename={filename}
                            instruments={instruments}
                            categories={categories}
                            chooseInstruments={chooseInstruments}
                            chooseCategories={chooseCategories}
                            errors={errors}
                            {...this.props} />
                    )}
                </Fragment>

                )
        }
    }
    HOC.displayName = `withInstrumentsItem(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withMusicItem