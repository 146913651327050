import React, {Fragment} from "react";

import withOneToMany from "./hoc";

const OneToMany = ({ value, list, handler, isActive, component, name }) => (
    <div  className={isActive ? "one-to-many active" : "one-to-many"}>
        <div className={"wrap"}>
            <div ref={component} className={"title"}>{value.name}</div>
            <div className={"list"}>
                {list.map(item => (
                    <Fragment key={item._id}>
                        {item._id !== value._id ? (
                            <div onClick={() => handler(name, item)} className={"list__item"}>{item.name}</div>
                        ) : ""}
                    </Fragment>
                ))}
            </div>
        </div>
    </div>
);


export default withOneToMany(OneToMany);