import React, {Fragment} from "react";

import withFileLoader from "./hoc";

import {MEDIA_PREFIX} from "../../etc/config"

const FileLoader = ({ name, uploadFile, filename, percent, title }) => (
    <div className={"file-loader"}>
        <label className={"button"} htmlFor={name}>
            { percent ? (
                <Fragment>
                    <div className="progress" style={{ width: `${percent}%` }} />
                    <div className={"percent"}>{percent}%</div>
                </Fragment>
            ) : (
                <div className="upload">{title ? title : "Load file"}</div>
            )}
        </label>
        <input onChange={uploadFile} type="file" id={name} name={name} />
        {filename ? (
            <a href={`${MEDIA_PREFIX}/Music/${filename}`} target={"_blank"}>{filename}</a>
        ) : ""}
    </div>
);


export default withFileLoader(FileLoader);