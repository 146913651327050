import React, {Fragment} from "react";
import { API_PREFIX } from "../../etc/config"
import { Redirect } from 'react-router'

const withInstrumentsItem = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                name: "",
                errors: [],
                redirect: false,
                isNew: true
            };
            this.handlerChange = this.handlerChange.bind(this);
            this.submit = this.submit.bind(this);
            this.get = this.get.bind(this);
        }

        componentDidMount() {
            if (this.props.match.params.id !== "new") {
                this.setState({isNew: false});
                this.get();
            }
        }

        handlerChange(name, value) {
            this.setState({[name]: value})
        }

        get() {
            fetch(`${API_PREFIX}admin/instruments/get/${this.props.match.params.id}`, {
                method: "get",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({name: res.data.name})
                    } else {
                        alert("Server error! Try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        submit(e) {
            e.preventDefault();
            const { name } = this.state;
            const errors = [];
            if (!name) errors.push("name");
            this.setState({ errors });

            if (errors.length > 0) {
                return
            }
            const {isNew} = this.state;
            fetch(`${API_PREFIX}admin/instruments/${isNew ? "add" : "edit"}`, {
                method: "post",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `name=${name}${!isNew ? `&id=${this.props.match.params.id}` : ""}`
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({redirect: true})
                    } else {
                        alert("Error, try again!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            const { name, errors, redirect } = this.state
            return (
                <Fragment>
                    {redirect ? (
                        <Redirect to={"/musical-instruments"} />
                    ) : (
                        <Component
                            handlerChange={this.handlerChange}
                            submit={this.submit}
                            name={name}
                            errors={errors}
                            {...this.props} />
                    )}
                </Fragment>

                )
        }
    }
    HOC.displayName = `withInstrumentsItem(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withInstrumentsItem