import React from "react";


const Preloader = () => (
    <div className={"preloader"}>
        <div className={"preloader__circle"}>
            <div className={"loader"}/>
        </div>
    </div>
)


export default Preloader;