import Home from "./Pages/Home";
import Music from "./Pages/Music";
import MusicItem from "./Pages/MusicItem";
import Categories from "./Pages/Categories";
import CategoriesItem from "./Pages/CategoriesItem";
import Instruments from "./Pages/Instruments";
import InstrumentsItem from "./Pages/InstrumentsItem";
import Breath from "./Pages/Breath";
import BreathItem from "./Pages/BreathItem";

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        title: "Home."
    },
    {
        path: "/music/:id",
        exact: true,
        component: MusicItem,
        title: "Music."
    },
    {
        path: "/music",
        exact: false,
        component: Music,
        title: "Music."
    },
    {
        path: "/musical-categories",
        exact: true,
        component: Categories,
        title: "Categories."
    },
    {
        path: "/musical-categories/:id",
        exact: false,
        component: CategoriesItem,
        title: "Category."
    },
    {
        path: "/musical-instruments",
        exact: true,
        component: Instruments,
        title: "Instruments."
    },
    {
        path: "/musical-instruments/:id",
        exact: false,
        component: InstrumentsItem,
        title: "Instrument."
    },
    {
        path: "/breath",
        exact: true,
        component: Breath,
        title: "Breath."
    },
    {
        path: "/breath/:id",
        exact: false,
        component: BreathItem,
        title: "Breath."
    },
]


export default routes