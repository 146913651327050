import React from "react";


const withOneToMany = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isActive: false
            };
            this.changeActive = this.changeActive.bind(this);
            this.component = React.createRef();
        }

        componentDidMount() {
            window.addEventListener("click", () => {
                if (this.state.isActive) {
                    this.setState({isActive: false})
                }
            });
            this.component.current.addEventListener("click", this.changeActive)
        }

        componentWillUnmount() {
            window.removeEventListener("click", () => {
                if (this.state.isActive) {
                    this.setState({isActive: false})
                }
            });
            this.component.current.removeEventListener("click", this.changeActive)
        }

        changeActive(e) {
            e.stopPropagation();
            this.setState({isActive: !this.state.isActive})
        }

        render() {
            return <Component
                component={this.component}
                isActive={this.state.isActive}
                changeActive={this.changeActive}
                {...this.props} />
        }
    }
    HOC.displayName = `withOneToMany(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withOneToMany