import React from "react";

import hoc from './hoc'

import TextField from "../../Components/TextField";

const Auth = ({ login, password, handlerChange, submit, errors }) => (
    <div className={"page auth-page"}>
        <form  onSubmit={submit}>
            <TextField
                isError={errors.includes("login")}
                handlerChange={handlerChange}
                value={login}
                name={"login"}
                type={"text"}
                label={"Login"}/>
            <TextField
                isError={errors.includes("password")}
                handlerChange={handlerChange}
                value={password}
                className={"password"}
                name={"password"}
                type={"password"}
                label={"Password"} />
                <button className={"button"} type={"submit"}>Log in</button>
        </form>
    </div>
)

export default hoc(Auth)