import React from "react";
import hoc from "./hoc"

const TextField = ({type, name, label, isActive, blur, focus, value, handlerChange, isError, className=""}) => (
    <div className={`text-field ${className} ${isError ? "error" : ""}`}>
        <input
            value={value}
            onChange={(e) => handlerChange(name, e.target.value)}
            onBlur={blur}
            onFocus={focus}
            type={type}
            name={name}
            autoComplete={"new-password"}
            id={name} />
        <label className={isActive ? "label active" : "label"} htmlFor={name}>{label}</label>
    </div>
)


export default hoc(TextField)