import React from "react";

import TextField from "../../Components/TextField";

import withInstrumentsItem from "./hoc";


const InstrumentsItem = ({handlerChange, submit, errors, name}) => (
    <div className={"page category-item"}>
        <form  onSubmit={submit}>
            <TextField
                isError={errors.includes("name")}
                handlerChange={handlerChange}
                value={name}
                name={"name"}
                className={"input"}
                type={"text"}
                label={"Category name"}/>
            <button className={"button"} type={"submit"}>Save</button>
        </form>
    </div>
);

export default withInstrumentsItem(InstrumentsItem)