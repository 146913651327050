import React from "react";
import TextField from "../../../Components/TextField";
import OneToMany from "../../../Components/OneToMany";

const Filter = ({ filterChange, search, categories, category, instruments, instrument }) => (
    <div className={"filter"}>
        <TextField
            isError={false}
            handlerChange={filterChange}
            value={search}
            name={"search"}
            type={"text"}
            label={"Search"}/>
            <OneToMany
                name={"category"}
                handler={filterChange}
                list={categories}
                value={category} />
        <OneToMany
            name={"instrument"}
            handler={filterChange}
            list={instruments}
            value={instrument} />
    </div>
);


export default Filter