import React from "react";



const Checkbox = ({ label, isActive, name, handler, value }) => (
    <div onClick={() => handler(name, value, !isActive)} className={"checkbox"}>
        <div className={isActive ? "checker active" : "checker"} />
        <span>{label}</span>
    </div>
)


export default Checkbox