import React from "react";
import {API_PREFIX} from "./etc/config";


const hoc = (Component) => {
    class GlobalHOC extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                isAuth: "checking"
            }
            this.checkAuth = this.checkAuth.bind(this);
            this.logout = this.logout.bind(this)
        }

        componentDidMount() {
            if (this.readCookie("auth_token") !== null) {
                this.checkAuth();
            } else {
                this.setState({isAuth: "no"})
            }
        }

        readCookie(name) {
            let name_cook = name + "=";
            let spl = document.cookie.split(";");
            for (let i = 0; i < spl.length; i++) {
                let c = spl[i];
                while (c.charAt(0) === " ") {
                    c = c.substring(1, c.length);
                }
                if (c.indexOf(name_cook) === 0) {
                    return c.substring(name_cook.length, c.length);
                }
            }
            return null;
        }

        logout() {
            document.cookie = "auth_token=; path=/; expires=" + new Date(0).toUTCString();
            this.setState({isAuth: "checking"}, () => {
                this.checkAuth();
            })

        }

        checkAuth() {
            fetch(`${API_PREFIX}admin/users/check`, {
                credentials: 'include'
            })
                .then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.setState({isAuth: "yes"})
                    } else {
                        this.setState({isAuth: "no"})
                    }
                })
        }

        render() {
            const { isAuth } = this.state;
            return <Component {...this.props} isAuth={isAuth} checkAuth={this.checkAuth} logout={this.logout} />
        }
    }
    GlobalHOC.displayName = `withAuth(${Component.displayName || Component.name || "Component"})`
    return GlobalHOC
};


export default hoc