import React from "react";


const withHeader = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                active: ""
            };
            this.changeLocation = this.changeLocation.bind(this)
        }

        componentDidMount() {
            this.setState({ active: window.location.pathname })
        }

        changeLocation(active) {
            this.setState({ active })
        }


        render() {
            return <Component
                changeLocation={this.changeLocation}
                active={this.state.active}
                {...this.props} />
        }
    }
    HOC.displayName = `withTextField(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withHeader