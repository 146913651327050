import React from 'react';
import { Route, Switch } from "react-router-dom";

import routes from "./routes";

import globalHoc from "./globalHOC"
import Auth from "./Pages/Auth";
import Preloader from "./Components/Preloader";
import Header from "./Components/Header";

const App = ({ isAuth, checkAuth, logout }) => {
  return (
    <div className="App">
        { isAuth === "yes" ? (
            <React.Fragment>
              <Header logout={logout}/>
              <Switch>
                {routes.map(({ path, exact, component: C, title, ...rest }) => (
                    <Route
                        key={path}
                        path={path}
                        exact={exact}
                        render={props => (
                            <C
                                {...props}
                                {...rest}
                                title={title}
                                checkAuth={checkAuth}
                            />
                        )}
                    />
                ))}
              </Switch>
            </React.Fragment>

        ) : isAuth === "no" ? (
            <Auth checkAuth={checkAuth} />
        ) : (
            <Preloader />
        ) }

    </div>
  );
}

export default globalHoc(App);
