import React from "react";
import {Link} from "react-router-dom";

import withInstruments from "./hoc";
import ContextMenu from "../../Components/ContextMenu";

const Instruments = ({ data, contextMenuPosition, contextMenuProperties, callContextMenu, removeItem }) => (
    <div className={"page musical-categories"}>
        <div className={"musical-categories__list"}>
            {data.map((item) => (
                <Link onContextMenu={(e) => callContextMenu(e, item._id)} to={`/musical-instruments/${item._id}`} key={item._id}>
                    {item.name}
                </Link>
            ))}
        </div>
        <Link to={"/musical-instruments/new"} className={"button add-button"}>+</Link>
        {contextMenuPosition ? (
            <ContextMenu
                menu={[{name: "Delete", properties: contextMenuProperties, handler: removeItem }]}
                position={contextMenuPosition}/>
                ) : ""}
    </div>
);

export default withInstruments(Instruments)